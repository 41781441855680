<template>
  <section>
    <!-- modal lista Cuentas Usuario -->
    <v-navigation-drawer id="modalCuentas" :class="{ fix: Fix }" v-model="ModalCryptoc" absolute>
      <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center"
        class="containerTitulo">
        <v-col class="paddvertical divcol">
          <span class="titulo h6-em">{{ $t("Cuenta Bancaria") }} </span>
          <span class="desc h7-em">{{ $t("Selecciona") }}</span>
        </v-col>
        <div class="divrow center">
        </div>
      </v-col>

      <v-card class="cartaInside cartaIE">
        <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
          <div class="divrow gap" style="justify-content: space-between; align-items: center">
            <span class="titulo h7-em paddtop paddbottom" style="line-height: 1 !important">
              {{ $t("Cuentas donde te depositaran tus Ventas") }}
            </span>
            <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB" @click="regresaVENTA()">
              <v-icon medium>mdi-arrow-left-thin</v-icon>
            </v-btn>
          </div>

        </v-col>

        <v-col class="contCuentaBancaria" cols="11" md="11" lg="10" style="margin-inline: auto">
          <v-card v-for="(item, index) in dataCuenta" :key="index" flat @click="selectCuenta_venta(item)" class="space"
            :class="{ active: item.habilitado }" style="display:flex">
            <span class="h8-em">{{ item.banco }}</span>
            <span class="h8-em">{{ item.titular }}</span>
            <aside class="divrow">
              <v-btn icon @click="openEditarCuenta(item)">
                <v-icon color="#6667AB">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="openDeleteCuenta(item)">
                <v-icon color="#6667AB">mdi-delete</v-icon>
              </v-btn>
            </aside>
          </v-card>
        </v-col>

        <v-col class="addCuenta" cols="11" md="11" lg="10" style="margin-inline: auto">
          <a class="h7-em" style="color: #2AB272" @click="dialogCuenta = true">
            <v-icon color="#2AB272">mdi-plus-circle-outline</v-icon>
            {{ $t("Agregar Cuenta") }}
          </a>
        </v-col>

      </v-card>
    </v-navigation-drawer>
    <!-- FIN modal lista Cuentas Usuario -->

    <!-- modal crea Cuentas Usuario -->
    <v-navigation-drawer id="modalCuentas" :class="{ fix: Fix }" v-model="dialogCuenta" absolute>
      <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center"
        class="containerTitulo">
        <v-col class="paddvertical divcol">
          <span class="titulo h6-em">{{ $t("CrearCuenta Bancaria") }}</span>
        </v-col>
        <div class="divrow center">
        </div>
      </v-col>
      <v-card class="cartaInside cartaIE">
        <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
          <div class="divrow gap" style="justify-content: space-between; align-items: center">
            <span class="titulo h7-em paddtop paddbottom" style="line-height: 1 !important">
              {{ $t("Cuentas donde te depositaran tus Ventas") }}
            </span>
            <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
              @click="dialogCuenta = false">
              <v-icon medium>mdi-arrow-left-thin</v-icon>
            </v-btn>
          </div>

        </v-col>

        <v-container>
          <v-card-text>
            <v-row class="ma-0 pb-5 px-2">
              <v-col class="col-6 ">
                <v-select v-model="bancoId" :items="dataBanco" item-text="Nombre" item-value="id" 
                 label="Bancos" outlined dense />
              </v-col>
              <v-col class="col-6 ">
                <v-select v-model="tipopagoId" :items="dataTipoPago" item-text="Nombre" item-value="id"
                  label="Tipo de Pago" outlined dense />
              </v-col>
              <v-col class="col-6">
                <v-text-field v-model="titular" outlined label="Titular" placeholder="Nombre del titular" hide-details
                  class="mb-3" dense />
              </v-col>
              <v-col class="col-6">
                <v-text-field v-model="cedula" outlined label="Id" placeholder="Nro. identificación" hide-details
                  class="mb-3" dense />
              </v-col>
              <v-col class="col-6">
                <v-text-field v-model="telefono" outlined label="Teléfono" placeholder="Teléfono Titular" hide-details
                  class="mb-3" dense />
              </v-col>
              <v-col class="col-6">
                <v-text-field v-model="numerocuenta" outlined label="Número Cuenta" placeholder="Número Cuenta"
                  hide-details class="mb-3" dense />
              </v-col>

              <v-col cols="11" md="11" lg="10" class="botonFinalEnviar" style="margin-inline: auto">
                <v-btn class="h8-em botonSwap" @click="dialogCuenta = false, createCountryCuenta()">
                  {{ $t("Guardar") }}
                </v-btn>
              </v-col>

            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-navigation-drawer>
    <!-- modal crea Cuentas Usuario -->

    <!-- modal EDITAR  Cuenta Usuario-->
    <v-navigation-drawer id="modalCuentas" :class="{ fix: Fix }" v-model="dialogeditCuenta" absolute>
      <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center"
        class="containerTitulo">
        <v-col class="paddvertical divcol">
          <span class="titulo h6-em">{{ $t("Cuenta Bancaria") }}</span>
          <span class="desc h7-em">{{ $t("Selecciona") }}</span>
        </v-col>
        <div class="divrow center">
        </div>
      </v-col>

      <v-card>
        <v-toolbar color="primary">
          <v-btn dark icon class="white--text" @click="dialogeditCuenta = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">{{ $t("Edición de Cuenta FIAT por País") }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-card-text>
            <v-row class="ma-0 pb-5 px-2">

              <v-col class="col-6 ">
                <v-select v-model="registroCuenta.bancoId" :items="dataBanco" item-text="nombre" item-value="id"
                  label="Bancos" outlined dense />
              </v-col>
              <v-col class="col-6 ">
                <v-select v-model="registroCuenta.tipopagoId" :items="dataTipoPago" item-text="nombre" item-value="id"
                  label="Tipo de Pago" outlined dense />
              </v-col>
              <v-col class="col-6">
                <v-text-field v-model="registroCuenta.titular" outlined label="Titular" placeholder="Nombre del titular"
                  hide-details class="mb-3" dense />
              </v-col>
              <v-col class="col-6">
                <v-text-field v-model="registroCuenta.cedula" outlined label="Id" placeholder="Nro. identidicación"
                  hide-details class="mb-3" dense />
              </v-col>
              <v-col class="col-6">
                <v-text-field v-model="registroCuenta.telefono" outlined label="Teléfono" placeholder="Teléfono Titular"
                  hide-details class="mb-3" dense />
              </v-col>
              <v-col class="col-6">
                <v-text-field v-model="registroCuenta.numerocuenta" outlined label="Número Cuenta"
                  placeholder="Número Cuenta" hide-details class="mb-3" dense />
              </v-col>
              <v-btn block color="primary" class="mt-6" @click="dialogeditCuenta = false, guardarEdicionCuenta()">
                Guardar
              </v-btn>

            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-navigation-drawer>
    <!-- FIN modal EDITAR  cuenta Usuario-->

    <!-- modal lista Cuentas DEFIX -->
    <v-navigation-drawer id="modalCuentas" :class="{ fix: Fix }" v-model="ModalCryptocDefix" absolute>
      <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center"
        class="containerTitulo">
        <v-col class="paddvertical divcol">
          <span class="titulo h6-em">{{ $t("Cuenta Bancaria") }}</span>
          <span class="desc h7-em">{{ $t("Selecciona") }}</span>
        </v-col>
        <div class="divrow center">
        </div>
      </v-col>

      <v-card class="cartaInside cartaIE">
        <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
          <div class="divrow gap" style="justify-content: space-between; align-items: center">
            <span class="titulo h7-em paddtop paddbottom" style="line-height: 1 !important">
              {{ $t("Cuentas donde pagaras tus Compras") }}
            </span>
            <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB" @click="regresaCOMPRA()">
              <v-icon medium>mdi-arrow-left-thin</v-icon>
            </v-btn>
          </div>

        </v-col>

        <v-col class="contCuentaBancaria" cols="11" md="11" lg="10" style="margin-inline: auto">
          <v-card v-for="(item, index) in dataCuentaDefix" :key="index" flat @click="selectCuenta_compra(item)"
            class="space" :class="{ active: item.habilitado }" style="display:flex">
            <span class="h8-em">{{ item.banco }}</span>
            <span class="h8-em">{{ item.titular }}</span>
          </v-card>
        </v-col>


      </v-card>
    </v-navigation-drawer>
    <!-- FIN modal lista Cuentas DEFIX -->


    <!-- Modal de Historico de Transacciones --> 
    <v-navigation-drawer id="modalCuentas" :class="{ fix: Fix }" v-model="modalFiatHistorico">
      <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
        <v-col class="paddvertical divcol">
          <span class="titulo h6-em">{{ $t("Historial de Transacciones") }}</span>
        </v-col>
      </v-col>
    
      <v-card id="fiat" style="border-radius: 30px !important">
        <v-card-title class="text-h5">
          <v-btn id="colorCloseWallet" style="top: 10px; right: 10px; position: absolute"
            @click="modalFiatHistorico = false">
            <v-icon medium>mdi-close</v-icon>
          </v-btn>
          <v-btn id="colorCloseWallet" style="top:50px; right: 10px; position: absolute" @click="fecthDatahistorico(user)">
            <v-icon medium>mdi-update</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <v-card-text>
    
            <v-col cols="12" md="11" lg="9" class="contPais divcol" style="margin-inline: auto ;">
              <v-card v-for="(item, index) in datahistorico" :key="index" class="divcol mb-5">
                <v-row @click="openEditar(item)">
                  <v-col cols="4">
                    <aside class="divrow" style="gap: clamp(.2em, .5vw, .5em)">
                      <div class="divcol" style="justify-content: center">
                        <span class="h8-em descStyle">{{ item.referencia }} </span>
                        <span class="h8-em descStyle">
                          <a v-if="item.estatus === '1'" style="color:blue !important">
                            Creado
                          </a>
                          <a v-if="item.estatus === '2'" style="color:orange !important">
                            Asignado
                          </a>
                          <a v-if="item.estatus === '3'" style="color:blue !important">
                            Procesado
                          </a>
    
                          <a v-if="item.estatus === '4'" style="color:green !important">
                            Completado
                          </a>
                          <a v-if="item.estatus === '5'" style="color:red !important">
                            Cancelado
                          </a>
                        </span>
                        <span class="h9-em descStyle">{{ (new Date(item.fecha_creado)).toLocaleString() }} </span>
                      </div>
    
                    </aside>
                  </v-col>
                  <v-col cols="4">
                    <aside class="divrow" style="gap: clamp(.2em, .5vw, .5em)">
                      <img class="token" :src="token" alt="token" />
                      <div class="divcol" style="justify-content: center">
    
                        <span class="desc h7-em">{{ "USDT" }}</span>
                        <span class="h8-em descStyle">
                          <a v-if="item.accion === 'C'" color="primary">
                            Compra
                          </a>
                          <a v-if="item.accion === 'V'" color="accent">
                            Venta
                          </a>
                        </span>
                      </div>
                    </aside>
                  </v-col>
                  <v-col cols="4">
                    <aside class="divrow" style="gap: clamp(.2em, .5vw, .5em)">
                      <div class="divcol" style="justify-content: center">
                        <span class="desc h7-em">{{ item.cantidad }}</span>
                        <span class="h8-em descStyle">{{ parseFloat(item.monto).toFixed(2) }} {{ item.coin }} </span>
                      </div>
                    </aside>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-card-text>
        </v-container>
      </v-card>
    </v-navigation-drawer>

    <!-- Modal de Historico de Transacciones - Detalle y archivo adjunto  -->
    <v-navigation-drawer id="modalCuentas" :class="{ fix: Fix }" v-model="modalAdjunto" absolute>
      <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
        <v-col class="paddvertical divcol">
          <span class="titulo h6-em">{{ $t("Detalle de la Transaccion") }}</span>
        </v-col>
      </v-col>
    
      <v-card id="fiat" style="border-radius: 30px !important">
        <v-card-title class="text-h5">
          <v-btn id="colorCloseWallet" style="top: 10px; right: 10px; position: absolute"
            @click="(modalAdjunto = false , modalFiatHistorico = true)">
            <v-icon medium>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <v-card-text>
            <!--v-col class="contVistaPreviaCompra divcol" cols="11" md="11" lg="10" style="margin-inline: auto"-->
              <v-col cols="12" md="11" lg="9" class="contPais divcol" style="margin-inline: auto ;">
              <aside class="divcol">
                <v-col class="col-4">
                  <div>
                    <!--img :src="'http://127.0.0.1:8000' + registro.imagen" fluid alt="Responsive image" width="100%" height="100%"-->
                    <img :src="registro.imagen" fluid alt="Responsive image" width="100%" height="100%">
                  </div>
                </v-col>
                <div class="space bold">
                  <span class="titulo h8-em">Usuario:</span>
                  <span class="h8-em">{{ registro.observacion }} </span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em">Estatus:</span>
                  <span class="h8-em" v-if="registro.estatus === '1'" style="color:blue !important">
                    Creado
                  </span>
                  <span class="h8-em" v-if="registro.estatus === '2'" style="color:orange !important">
                    Asignado
                  </span>
                  <span class="h8-em" v-if="registro.estatus === '3'" style="color:blue !important">
                    Procesado
                  </span>
    
                  <span class="h8-em" v-if="registro.estatus === '4'" style="color:green !important">
                    Completado
                  </span>
                  <span class="h8-em" v-if="registro.estatus === '5'" style="color:red !important">
                    Cancelado
                  </span>
                </div>
    
                <div class="space bold">
                  <span class="titulo h8-em">Acción</span>
                  <span class="h8-em" v-if="registro.accion === 'C'" color="primary">
                    Compra
                  </span>
                  <span class="h8-em" v-if="registro.accion === 'V'" color="accent">
                    Venta
                  </span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em">Cripto</span>
                  <span class="h8-em"> {{ registro.cripto }} </span>
                </div>
    
                <div class="space bold">
                  <span class="titulo h8-em">Referencia</span>
                  <span class="h8-em"> {{ registro.referencia }} </span>
                </div>
    
                <div class="space bold">
                  <span class="titulo h8-em">Tasa </span>
                  <span class="h8-em"> {{ parseFloat(registro.tasa).toFixed(2) }} </span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em">Cantidad<br>{{ registro.cripto }} <br></span>
                  <span  v-if="registro.accion === 'V'" 
                  class="h8-em"> {{ registro.cantidad }} </span>
                  <span v-else 
                  class="h8-em">{{ parseFloat(registro.monto).toFixed(2) }}</span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em"> Monto <br>{{ registro.coin }}</span>
                  <span v-if="registro.accion === 'V'"
                  class="h8-em">{{ parseFloat(registro.monto).toFixed(2) }}</span>
                  <span  v-else 
                  class="h8-em"> {{ registro.cantidad }} </span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em"> Banco <br></span>
                  <span class="h8-em">{{ registro.banco_nombre }}</span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em"> Tipo de Pago <br></span>
                  <span class="h8-em"> {{ registro.tipopago_nombre }}</span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em"> Titular <br></span>
                  <span class="h8-em">{{ registro.titular }}</span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em"> Cédula <br></span>
                  <span class="h8-em">{{ registro.cedula }}</span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em"> Teléfono <br></span>
                  <span class="h8-em">{{ registro.telefono }}</span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em"> Número de Cuenta <br></span>
                  <span class="h8-em">{{ registro.numerocuenta }}</span>
                </div>
                <div class="space bold">
                  <span class="titulo h8-em"> Fecha Creado: </span>
                  <span class="h8-em">{{ (new Date(registro.fecha_creado)).toLocaleString() }}</span>
                </div>
                <v-col class="col-12">
                  <span class="titulo h6-em">{{ $t("Archivo Adjunto") }}</span>
                  <div v-if="registro.adjuntofiat === 'vacio'">
                    <div v-if="registro.accion === 'C'">
                      <v-col class="col-12 mb-0">
                        <v-row>
                          <v-col md="10">
                            <v-file-input @change="uploadBandera(registro)" v-model="registro.adjuntofiat" accept="image/*"
                              label="Cargar Adjunto" />
                          </v-col>
                        </v-row>
                      </v-col>
    
                    </div>
                  </div>
                  <div v-else>
                    <!--img :src="'http://127.0.0.1:8000' + registro.adjuntofiat" fluid alt="Responsive image" width="100%" height="100%"-->
                    <img :src="registro.adjuntofiat" fluid alt="Responsive image" width="100%" height="100%">
                  </div>
                </v-col>
              </aside>
            </v-col>
          </v-card-text>
        </v-container>
      </v-card>
    </v-navigation-drawer>

    <!-- Lista de paises con KYC  -->
    <v-navigation-drawer id="modalCuentas" :class="{ fix: Fix }" v-model="modalKYC" absolute>
      <v-col cols="11" md="11" lg="10" style="justify-content: space-between; align-items: center" class="containerTitulo">
        <v-col class="paddvertical divcol">
          <span class="titulo h6-em">{{ $t("Selecciona el País") }}</span>
        </v-col>
        <div class="divrow center">
        </div>
      </v-col>
      <v-card style="border-radius: 30px !important">
        <v-card-title class="text-h5">
          <v-btn id="colorCloseWallet" style="top: 10px; right: 10px; position: absolute" @click="modalKYC = false">
            <v-icon medium>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <v-card-text>
            <v-col cols="12">
    
              <v-data-table :headers="headersPais" :items="data" :items-per-page="8" class="elevation-1" sort-by="estatus">
                <template #[`item.imagen`]="{ item }">
                  <div style="cursor:pointer !important;">
                    <v-img :src="item.imagen" style="border-radius: 5px !important;" fluid alt="Responsive image"
                      width="30%" height="30%" @click="selectCountry1(item)"
                      v-if="item.estatus === 'X' || item.estatus === 'C'">
                    </v-img>
    
                    <v-img :src="item.imagen" style="border-radius: 5px !important;" fluid alt="Responsive image"
                      width="30%" height="30%" v-else></v-img>
                  </div>
    
                </template>
                <template #[`item.estatus`]="{ item }">
                  <v-icon dark color="primary" v-if="item.estatus === 'R'">
                    {{ icons.mdiTimerSand }}
                  </v-icon>
                  <v-icon dark color="#0bf618" v-if="item.estatus === 'A'">
                    {{ icons.mdiThumbUpOutline }}
                  </v-icon>
                  <v-icon dark color="#E53935" v-if="item.estatus === 'C'">
                    {{ icons.mdiFingerprintOff }}
                  </v-icon>
                  <v-icon dark color="#E53935" v-if="item.estatus === 'X'">
                    {{ icons.mdiCropFree }}
                  </v-icon>
                </template>
    
                <template #[`item.descripcion`]="{ item }">
                  <h7 v-if="item.estatus === 'R'" style="color:orange !important">
                    {{ $t("Proceso de Revision") }}
                  </h7>
                  <h7 v-if="item.estatus === 'A'" style="color:blue !important">
                    {{ $t("KYC Aprobado") }}
                  </h7>
                  <h7 v-if="item.estatus === 'X'" style="color:green !important">
                    {{ $t("Carge sus datos para validar KYC") }}
                  </h7>
                  <h7 v-if="item.estatus === 'C'" style="color:red !important">
                    {{ $t("KYC NO aprobado") }}
                  </h7>
                </template>
    
    
                <template #[`item.acciones`]="{ item }">
                  <v-btn icon dark color="primary" @click="OpenCryptoc(item)" v-if="item.estatus === 'A'">
                    <v-icon>{{ icons.mdiBankPlus }}</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-card-text>
        </v-container>
      </v-card>
    </v-navigation-drawer>

    <!-- selecciona juridico -->
    <v-navigation-drawer id="modalCuentas" :class="{ fix: Fix }" v-model="modalJuridico" absolute>
      <v-row style="margin-inline: auto;margin-top: 25px !important;">
        <v-col cols="6" md="4" lg="4" class="contCompraVenta" style="margin-inline: auto;">
          <v-btn block class="h8-em " @click="ValidaJuridico('N')" style="background-color: #6667AB; color: #FFFF;
                             float:center;
                                           border-radius: 30px; padding: 1.1em; text-transform: none !important"
            :disabled="isActiveNatural">
            {{ $t("Natural") }}
          </v-btn>
        </v-col>
        <v-col cols="6" md="4" lg="4" class="contCompraVenta divcol center" style="margin-inline: auto">
          <v-btn block class="h8-em mr-5" @click="ValidaJuridico('J')" style="background-color: #6667AB; color: #FFFF;  float:center;
                                           border-radius: 30px; padding: 1.1em; text-transform: none !important"
            :disabled="isActiveJuridico">
            {{ $t("Juridico") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-col cols="11" md="11" lg="10" style="margin-inline: auto" class="divcol">
        <div class="divrow" style="justify-content: space-between; align-items: center">
          <span class="titulo h6-em paddtop paddbottom">{{ $t("DocumentosKYC") }}</span>
          <v-btn class="flechitaLeft" icon color="#FFFFFF" style="background-color: #6667AB"
            @click="modalCarga = false, modalJuridico = false, modalKYC = true">
            <v-icon medium>mdi-arrow-left-thin</v-icon>
          </v-btn>
        </div>
      </v-col>



      
    </v-navigation-drawer>

    <!-- carga archivos y datos de kyc -->
    <v-navigation-drawer id="modalJuridico" :class="{ fix: Fix }" v-model="modalCarga" absolute>


      <v-col cols="12">
        <v-card class="cartaInside cartaIE" style="padding-top: 16px">
          <v-col md="12" lg="12" class="cardHistorico cardH4" style="margin-inline: auto">
            <v-form v-model="valid" ref="form">
              <v-col v-for="(item, index) in dataDoc" :key="index" style="margin-inline: auto" class="divcol">
                <v-card v-if="item.juridico === juridico" elevation="5" outlined>
                  <!--v-card v-if="item.tipo === 'A'" max-width="600" elevation="5" outlined-->
                  <v-card-text v-if="item.tipo === 'I'">
                    <v-row class="ma-0">
                      <v-col sm="12">
                        <v-row class="ma-0 pb-1 px-0">
                          <v-col v-if="item.opcional === 'S'" class="col-6">
                            <span class="h7-em" >{{ item.nombre }}*</span>
                          </v-col>

                          <v-col v-else class="col-6">
                            <span class="h7-em" >{{ item.nombre }}</span>
                          </v-col>

                          <v-col class="col-6">
                            <v-img :src="Preview_image(item.imagen)"></v-img>
                          </v-col>
                          <v-col class="col-12">
                            <v-file-input v-model="item.imagen" 
                                          :hint="item.detalle" 
                                          :rules="fileRules"
                                          persistent-hint accept="image/*" />
                          </v-col>
                        </v-row>

                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text v-else>
                    <v-row class="ma-0">
                      <v-col sm="12">
                        <v-row class="ma-0 pb-1 px-0">
                          <v-col v-if="item.opcional === 'S'" class="col-6">
                            <span class="h7-em" >{{ item.nombre }}*</span>
                          </v-col>

                          <v-col v-else class="col-6">
                            <span class="h7-em" >{{ item.nombre }}</span>
                          </v-col>
                          <v-col class="col-6">
                            <v-text-field v-model="item.texto" 
                                          :hint="item.detalle" 
                                          :rules="nameRules"
                                          persistent-hint />
                          </v-col>
                        </v-row>

                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

              </v-col>
            </v-form>




          </v-col>

                      <v-layout justify-space-between>
                        <v-col cols="11" md="11" lg="10" class="botonFinalEnviar" style="margin-inline: auto">
                          <v-btn class="h8-em botonSwap" 
                                :disabled="!valid" 
                                @click="CreaKYC()">
                            {{ $t("Guardar") }}
                          </v-btn>
                        </v-col>
                      </v-layout>
        </v-card>
      </v-col>

    </v-navigation-drawer>


  </section>
</template>

<script>
import axios from "axios";
import { i18n } from "@/plugins/i18n";
import {
  mdiAccountPlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiClose,
  mdiPencil,
  mdiDelete,
  mdiTextBoxPlusOutline  ,
  mdiEarthPlus ,
  mdiCashPlus ,
  mdiBankPlus,
  } from '@mdi/js'

export default {
  name: "ModalCryptoc",
  i18n: require("./i18n"),
  computed: {   
    headersPais () {
      return [
        { text: this.$t('País'), value: 'imagen' ,width: '50%'},
        { text: this.$t('Descripcion'), value: 'descripcion'},
        //{ text: this.$t('Estatus'), value: 'estatus'},
        { text: this.$t('Pagos'), value: 'acciones'}
      ]}},


  data() {
    return {
      valid: true,
      nameRules: [
        v => !!v || 'Descripción is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      fileRules: [
        v => !!v || 'File is required',
        v => (v && v.size > 0) || 'File is required',
      ],
      juridico:'',
      isActiveJuridico: true,
      isActiveNatural: true,
      cuentasDefixMenu:false,
      modalFiatHistorico:false,
      modalKYC:false,
      modalAdjunto:false,
      datahistorico:[],
      data:[],
      dataDoc:[],
      item:[],
      token: require("@/assets/app/token/usdt.svg"),
      registro:{
        id:'',
        imagen:'',
        estatus:'',
        observacion:'',
        accion:'',
        referencia:'',
        pais:'',
        cripto:'',
        tasa:'',
        comision:'',
        cantidad:'',
        monto:'',
        banco:'',
        banco_nombre:'',
        tipopago:'',
        tipopago_nombre:'',
        titular:'',
        cedula:'',
        telefono:'',
        numerocuenta:'',
        tipocuenta:'',
        usuario:'',
        fecha_creado:'',
        fecha_asignado:'',
        usuario_asignado:'',
        fecha_procesado:'',
        fecha_completado:'',
        fecha_anulado:'',
        coin:'',
        adjuntofiat:'',
        estatus2:false,
        estatus3:false,
        estatus4:false,
        estatus5:false,
      },      

      dataCuenta:[],
      dataCuentaDefix:[],
      dataBanco:[],  
      dataTipoPago:[], 
      dialogCuenta: false,
      dialogeditCuenta:false,  
      dataDeleteCuenta: [],
      id: '',
      bancoId:'',
      tipopagoId:'',
      titular:'',
      cedula:'',
      telefono:'',
      numerocuenta:'',
      registroCuenta:{
        id:'',
        bancoId:'',
        tipopagoId:'',
        titular:'',
        cedula:'',
        telefono:'',
        numerocuenta:'',
      }, 

      step: 0,    
      Fix: false,
      ModalCryptoc: false,
      ModalCryptocDefix: false,
      positionModal: true,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiAccountPlus,
        mdiClose,
        mdiPencil,
        mdiDelete,
        mdiTextBoxPlusOutline  ,
        mdiEarthPlus ,
        mdiCashPlus ,
        mdiBankPlus,
      },
      kyccabeceraId:'',
      paisId:'',
      user:'',
      modalJuridico:false,
      modalCarga:false,
    };
  },
  mounted() {
    if (window.innerWidth <= 880) {
      this.positionModal = false
    } else {
      this.positionModal = true
    }
    },
  methods: {
    changeLanguaje(lang) {
      if (lang === "ES") {
        localStorage.setItem('language', lang)
        //localStorage.language = lang;
        i18n.locale = lang;
      } else {
        localStorage.setItem('language', lang)
        //localStorage.language = lang;
        i18n.locale = lang;
      }
    },
    uploadBandera(item){
      const formData = new FormData()
      formData.append('adjuntofiat', item.adjuntofiat)
      axios.patch(process.env.VUE_APP_API_BASE_AXIOS + '/FiatTransaccion/'+item.id+'/', formData,{
        headers: {"Content-Type": "multipart/form-data",},
      }).then((res)=>{
        console.log(res)
        this.$toast.success('Registro exitoso')
        this.modalAdjunto= false
        this.fecthDatahistorico(this.user) 
      }).catch((error) => {
        this.$toast.error('Error en el registro de dato',error)
        console.error(error)
      })
    },
    fecthDataKYC(user) {
          this.user=user
          //axios.get( process.env.VUE_APP_API_BASE_AXIOS + '/get-paises/'+user+'').then(response => {
          axios.get( process.env.VUE_APP_API_BASE_AXIOS + '/get-paises').then(response => {
          this.data = response.data
          this.modalKYC = true
          this.cuentasDefixMenu=false
          
        }).catch(err => {
          console.log(err)
        })
    },
    selectCountry1(item) {
      this.data.forEach(element => {
        element.active = false
      });
      this.modalKYC = false
      item.active = true
      this.banco_cuenta=' '
      //this.llaves.pais = item.id;
      this.fecthDataDoc(item.id)
      this.modalJuridico=true
    },
    ValidaJuridico(Var) {
      this.juridico=Var
      this.modalCarga=false
      this.modalCarga=true
      //modalCarga=false,
      this.modalJuridico=true
    },
    Preview_image(imagen) {
      if( imagen){
       return URL.createObjectURL(imagen)
      }
      else {
        return null
      }
    },
    OpenCryptoc(item){
      this.cuentasDefixMenu=true
      this.fecthDatac(item.kycCabeceraId,item.id)
      this.modalKYC= false
      this.Fix = true;
      this.ModalCryptoc = true;
      setTimeout(() => {
        this.Fix = false;
      }, 1000);
    },
    CreaKYC() {
      const formData = new FormData()
      formData.append('cabecera-id_pais',this.pais_id)
      formData.append('cabecera-juridico',this.juridico)
      formData.append('cabecera-usuario','1')
      formData.append('cabecera-observacion',this.user)
      formData.append('cabecera-banco_cuenta',this.banco_cuenta)
      formData.append('cabecera-email',localStorage.getItem('email'))
      this.banco_cuenta=' '
      this.dataDoc.forEach((value) => {
      if(this.juridico===value.juridico){
          if(value.tipo==='I'){
            formData.append('cuerpo-I-'+value.nombre, value.imagen)
            const formDataImagen = new FormData()
            formData.append('imagen',  value.imagen)
            axios.post(process.env.VUE_APP_API_BASE_AXIOS + '/kycdetalle/', formDataImagen,{
              headers: {"Content-Type": "multipart/form-data",},
            }).then((res)=>{
              console.log(res)
            }).catch((error) => {
              console.error(error)
            })
          }
          else{
            formData.append('cuerpo-T-'+value.nombre, value.texto)
          }
      }
      })
      axios.post(process.env.VUE_APP_API_BASE_AXIOS + '/crear-kyc', formData).then((res) => {

        axios.put( process.env.VUE_APP_API_BASE_AXIOS + '/SendEmailKYC', formData).then((res)=>{
              console.log(res)
            }).catch((error) => {
              console.error(error)
            })


        console.log(res.data)
        this.snackbar = true
        this.color = 'primary'
        this.text = 'Se guardo correctamente'
        this.modalCarga=false
        this.modalJuridico=false
        this.$parent.OpenKYC()

        //this.modalKYC=true
      }).catch((err) => {
        console.log(err)
        this.snackbar = true
        this.color = '#E53935'
        this.text = 'Ocurrio un error ' + err
      });
    },
    fecthDataDoc (id) {
          this.pais_id = id
          axios.get(process.env.VUE_APP_API_BASE_AXIOS + '/get-paisesdoc/'+id).then(response => {
          this.data_campos = []
          this.dataDoc = response.data
          this.isActiveJuridico= true
          this.isActiveNatural= true
          
          this.dataDoc.forEach((val) => {
            if (val.juridico=='J'){this.isActiveJuridico= false }
            if (val.juridico=='N'){ this.isActiveNatural= false }
          });

        }).catch(err => {
          console.log(err)
        })
    },
    fecthDatahistorico(user) {
          this.user=user
          axios.get( process.env.VUE_APP_API_BASE_AXIOS + '/get-fiat-user/'+user+'').then(response => {
          this.datahistorico = response.data
          this.modalFiatHistorico = true
        }).catch(err => {
          console.log(err)
        })
    },
    openEditar(item){
      console.log(item)
      this.modalAdjunto = true
      this.registro = item
      //this.url=null
      //this.registro.adjuntofiat = 'vacio'
    },
    regresaCOMPRA(){
      this.$parent.step = 4
      this.ModalCryptocDefix= false
    },
    regresaVENTA(){
      if (this.cuentasDefixMenu){
        this.cuentasDefixMenu=false
        this.modalKYC=true
      }
      else{
        this.$parent.step = 4
      }
      this.ModalCryptoc= false
    },
    selectCuenta_venta(item) {
      if (this.cuentasDefixMenu == false){
      this.ModalCryptoc= false
      this.$parent.selectCuenta_venta(item);        
      }
    },
    selectCuenta_compra(item) {
      this.ModalCryptocDefix= false
      this.$parent.selectCuenta_compra(item);
    },
    fecthDatac(kyccabeceraId,paisId) {
          this.kyccabeceraId=kyccabeceraId
          this.paisId=paisId
          axios.get( process.env.VUE_APP_API_BASE_AXIOS + '/get-tcuenta/'+kyccabeceraId+'').then(response => {
          this.dataCuenta = response.data
        }).catch(err => {
          console.log(err)
        })

          axios.get(process.env.VUE_APP_API_BASE_AXIOS + '/paisbanco/?pais='+paisId+'').then(response => {
          this.dataBanco = response.data
        }).catch(err => {
          console.log(err)
        })
          axios.get(process.env.VUE_APP_API_BASE_AXIOS + '/tipopago/').then(response => {
          this.dataTipoPago = response.data
        }).catch(err => {
          console.log(err)
        })
    },
    fecthDatacDefix(paisId) {
          this.paisId=paisId
          axios.get( process.env.VUE_APP_API_BASE_AXIOS + '/get-tcuenta-defix/'+paisId+'').then(response => {
          this.dataCuentaDefix = response.data
        }).catch(err => {
          console.log(err)
        })
          axios.get(process.env.VUE_APP_API_BASE_AXIOS + '/paisbanco/?pais='+paisId+'').then(response => {
          this.dataBanco = response.data
        }).catch(err => {
          console.log(err)
        })
          axios.get(process.env.VUE_APP_API_BASE_AXIOS + '/tipopago/').then(response => {
          this.dataTipoPago = response.data
        }).catch(err => {
          console.log(err)
        })
    },
    createCountryCuenta () {
      const data = {
        banco:this.bancoId,
        tipopago:this.tipopagoId,
        titular:this.titular,
        cedula:this.cedula,
        telefono:this.telefono,
        numerocuenta:this.numerocuenta,
        kyccabecera: this.kyccabeceraId,
      }
      console.log('lola',data)
        axios.post(process.env.VUE_APP_API_BASE_AXIOS + '/kyccuenta/', data).then((res) => {
        console.log(res.data)
        this.snackbar = true
        this.color = 'primary'
        this.text = 'Se guardo correctamente'
        this.bancoId = ''
        this.tipopagoId = ''
        this.titular = ''
        this.cedula = ''
        this.telefono = ''
        this.numerocuenta = ''
        this.fecthDatac(this.kyccabeceraId,this.paisId)
      }).catch((err) => {
        console.log(err)
        this.snackbar = true
        this.color = '#E53935'
        this.text = 'Ocurrio un error ' + err
      });
    }, 
    openEditarCuenta(item){
      console.log(item)
      this.dialogeditCuenta = true
      this.registroCuenta.id = item.id
      this.registroCuenta.bancoId = item.bancoId
      this.registroCuenta.tipopagoId = item.tipopagoId
      this.registroCuenta.titular = item.titular
      this.registroCuenta.cedula = item.cedula
      this.registroCuenta.telefono = item.telefono
      this.registroCuenta.numerocuenta = item.numerocuenta
      this.registroCuenta.habilitado = item.habilitado
    },
    guardarEdicionCuenta() {
        const formData = new FormData()
        formData.append('banco', this.registroCuenta.bancoId)
        formData.append('tipopago', this.registroCuenta.tipopagoId)
        formData.append('titular', this.registroCuenta.titular)
        formData.append('cedula', this.registroCuenta.cedula)
        formData.append('telefono', this.registroCuenta.telefono)
        formData.append('numerocuenta', this.registroCuenta.numerocuenta)
        formData.append('habilitado', this.registroCuenta.habilitado)
        axios.put(process.env.VUE_APP_API_BASE_AXIOS + '/kyccuenta/'+ this.registroCuenta.id + '/', formData).then((res) => {
          console.log(res.data)
        this.snackbar = true
        this.color = 'primary'
        this.text = 'Se actualizo correctamente'
        this.fecthDatac(this.kyccabeceraId,this.paisId)


      }).catch((err) => {
        console.log(err)
        this.snackbar = true
        this.color = '#E53935'
        this.text = 'Ocurrio un error' + err
      });
    },
    openDeleteCuenta (item) {
      this.dataDeleteCuenta = item
      axios.delete(process.env.VUE_APP_API_BASE_AXIOS + '/kyccuenta/' + this.dataDeleteCuenta.id + '/').then(res => {
        console.log(res)
        this.snackbar = true
        this.color = 'primary'
        this.text = 'Se elimino correctamente'
        this.fecthDatac(this.kyccabeceraId,this.paisId)
      }).catch(err => {
        console.log(err)
        this.snackbar = true
        this.color = '#E53935'
        this.text = 'Ocurrio un error' + err
      })
    },
    selectTokenC(item) {
      this.$parent.selectTokenC(item);
    },
  },
};
</script>

<style src="../Right/Right.scss" lang="scss"></style>
<style scoped>
.containerLineas {
  position: relative;
}
.containerLineas::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: 1px;
  width: 100%;
  background-color: #3333332a;
}
</style>
